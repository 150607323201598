@import '../../res/colours.scss';

.parallax-container {
    position: relative;

    .section-background {
        // background: fixed;
        // background-position: center;
        // background-size: cover;
        // background-color: $pink-new-colour;
        background: linear-gradient(to right, #facee6, #ffffff);
        min-height: 50vh;
        width: 100%;
        display: block;
        margin: 0 auto;
        text-align: center;
        z-index: 0;


        &::after {
            content: '';
            // background: rgba(0, 0, 0, 0.3);
            position: absolute;
            inset: 0;
        }
    }

    .section-background {
        position: relative;
        z-index: 2;
    }
}