@import '../../../res/global.scss';
@import '../../../res/colours.scss';

$box-shadow-color: rgb(7, 76, 79);
$icon-color: rgb(202, 215, 226);
$details-color: rgb(185, 206, 224);

.expert-card {
    position: relative;
    width: 250px;
    height: 350px;
    // box-shadow: 0 0 10px $box-shadow-color;
    border-radius: 20px;
    overflow: hidden;
    color: white;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    transition: all 500ms;

    // &:hover {
    //     box-shadow: 10px 10px 20px $box-shadow-color;
    // }

    .gradient {
        height: 100%;
        width: 100%;
        top: 0;
        background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(253, 252, 252, 0.21));
        color: white;

        &:hover {
            background: transparent;
        }
    }

    .content {
        bottom: 0;
        position: absolute;
        margin-bottom: 10px;
        padding: 20px;
        width: 100%;

        h2 {
            font-size: xx-large;
            letter-spacing: -1px;
        }

        .details {
            font-style: italic;
            color: $details-color;
            max-height: 3rem;
            overflow-y: scroll;
            padding-right: 15px;
        }

        .icons {
            display: flex;
            flex-direction: column;
            bottom: 0;
            position: absolute;
            right: 5%;
            
            i {
                font-size: 20px;
                margin-bottom: 10px;
                color: $icon-color;
                transition: 0.3s;

                &:hover {
                    cursor: pointer;
                    color: white;
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        .gradient {
            background: transparent;
        }    
    }
}