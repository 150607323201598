@import '../../res/colours.scss';
@import '../../res/keyframes.scss';
@import '../../constants/constants.scss';

.services__section {
    background: white;



    .radio-group-section {
        position: sticky;
        top: calc($navbar-height + 10px);
        z-index: 2;
    }


    // .pricelist[data-tab="0"],
    // .pricelist[data-tab="1"],
    // .pricelist[data-tab="2"] {
    //     animation: fadeIn 0.8s ease-out forwards;
    // }

    .pricelist {
        width: 100%;
        padding: 6px 2em 6rem 2em;


        .title {
            text-align: center;
            margin-bottom: 2.5rem;


            p {
                margin-top: 1rem;
                line-height: 1.75rem;
                color: rgb(107 114 128 / 1);
            }

            h3 {
                margin-top: 1rem;
                font-size: 1.8rem;
                line-height: 0.25rem;
            }
        }

        .ttable {

            .theader {
                padding: 0.75rem 1.25rem;
                background-color: #EEE;
                color: #6B7280;
                font-size: 1rem;
                line-height: 1.25rem;
                font-weight: 500;
                text-transform: uppercase;
                // border-radius: 0.5rem;

                position: sticky;
                top: calc($navbar-height + 60px);
                z-index: 1;
            }

            // @for $i from 0 to 15 {
            //     &:nth-child(#{$i}) > .tContent {
            //         position: sticky;
            //         top: calc($navbar-height + 60px + ($i * 60px));
            //     }
            // }

            .aestheticCategory {
                text-transform: capitalize;
                font-weight: 900;
                font-size: 1.25rem;
                padding-top: 1rem;
            }

            .tContent {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
                margin-top: 1rem;
                margin-bottom: 1.5rem;
                padding: 0rem 1rem;
                border-bottom: 2px solid rgba(211, 211, 211, 0.45);


                .left {

                    .item-title {
                        text-transform: capitalize;
                        color: #111827;
                        font-weight: 600;
                    }

                    .item-subTitle {
                        color: #6B7280;
                        font-size: 0.875rem;
                        // line-height: 1.25rem; 

                        line-height: 1rem;
                        margin-top: 0;
                    }
                }

                .right {

                    .price,
                    .sale-price {
                        font-size: 1.13rem;
                        font-weight: 500;
                    }

                    .sale-price {
                        color: lightgray;
                        text-decoration-line: line-through;
                    }

                    .cta-button {
                        background: $pink-dark-colour;
                        // padding: 8px 15px;
                        margin-left: 20px;

                        svg {
                            width: 20px;
                            height: 20px;
                            color: white;
                        }

                        &:hover {
                            background: darken($pink-dark-colour, 20%);
                        }
                    }
                }
            }
        }
    }




    /* ===========================
   ====== Media Query's ====== 
   =========================== */
    @media only screen and (max-width: 992px) {}

    @media only screen and (max-width: 768px) {
        .pricelist {
            padding: 6px 0em 6rem 0em;

            .ttable {
                .theader {
                    font-size: 0.7rem;
                }

                .tContent {
                    .left {
                        .item-title {
                            font-size: 0.8rem;
                        }

                        .item-subTitle {
                            font-size: 0.7rem;
                        }
                    }

                    .right {

                        .price,
                        .sale-price {
                            margin-right: 10px;
                            font-size: 0.9rem;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 576px) {}
}