@import '../../../res/mixin.scss';
@import '../../../res/colours.scss';

.geo-container {
    position: fixed;
    inset: 0;
    z-index: 5;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: rgba(0, 0, 0, 0.6);
    // @include blur(4px);


    #formContent {
        border-radius: 10px 10px 10px 10px;
        background: #fff;
        // padding: 30px;
        width: 100%;
        max-width: 650px;
        position: relative;
        padding: 30px;
        -webkit-box-shadow: 0 30px 60px 0 rgba($pink-new-colour, 0);
        box-shadow: 0 30px 60px 0 rgba($pink-new-colour, 0);
        text-align: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        pointer-events: all;
        padding: 20px 30px;
        text-align: center;


        .heading {
            margin-bottom: 20px;

            color: $green-shade-colour;
            font-size: 2rem;
            font-weight: bolder;
            line-height: 2;
            // animation: slideFromRight 1.1s forwards;
        }

        .subheading {
            font-size: 1.1rem;
            color: gray;
            word-wrap: pre;
            margin-bottom: 20px;
        }

        .submitButton {
            // background: $pink-new-colour;
            // // border: none;
            // color: black;
            // padding: 15px 80px;
            // // text-align: center;
            // // text-decoration: none;
            // display: inline-block;
            // text-transform: uppercase;
            // // font-size: 13px;
            // -webkit-box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
            // box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
            // // -webkit-border-radius: 5px 5px 5px 5px;
            // // border-radius: 5px 5px 5px 5px;
            // margin: 5px 20px 20px 20px;



            color: #fff;
            text-transform: uppercase;
            border-radius: 5px;
            background: $pink-dark-colour;
            font-size: 1rem;
            padding: 15px 80px;
            margin: 5px 20px 20px 20px;
            transition: all .3s;

            &:hover {
                box-shadow: 5px 5px 20px rgba($green-shade-colour, 0.5);
                background: darken($pink-dark-colour, 20%);
            }
        }

        .close {
            position: absolute;
            top: 1rem;
            right: 1rem;
            width: 1.3rem;
            height: 1.3rem;
            background: transparent;
            z-index: 2;
            transition: 0.3s ease-out;
    
            svg {
                color: $pink-new-colour;
                transition: 0.3s ease-out;
            }
    
            &:hover {
                cursor: pointer;
                transform: scale(1.1);

                svg {
                    color: $pink-dark-colour;
                }
            }
        }
    }
}