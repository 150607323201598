@import '../../res/colours.scss';

.accordion {
    margin-left: auto;
    margin-right: auto;
    max-width: 44rem;

    .drawer {
        margin-bottom: 30px;
        border-top: $pink-new-colour 1px solid;

        &__content-wrapper {
            font-size: 1.25em;
            font-weight: 500;
            line-height: 1.4em;
            max-height: 0px;
            opacity: 0;
            overflow: hidden;
            visibility: hidden;
            transition: all 0.25s ease-in-out;
        }

        &__content {
            font-size: 1rem;
            color: #686868;
            white-space: pre-wrap;
        }

        &__title {
            cursor: pointer;
            display: block;
            font-size: 1.25em;
            font-weight: 700;
            padding: 30px 0 0 0;
            position: relative;
            margin-bottom: 0;
            transition: all 0.25s ease-out;

            &::after {
                border-style: solid;
                border-width: 1px 1px 0 0;
                // color: $pink-new-colour;
                content: " ";
                display: inline-block;
                float: right;
                height: 10px;
                left: 2px;
                position: relative;
                right: 20px;
                top: 2px;
                transform: rotate(135deg);
                transition: 0.35s ease-in-out;
                vertical-align: top;
                width: 10px;
            }

            &:hover {
                color: #4E4B52;
            }
        }
    }

    .drawer__trigger:checked+.drawer__title+.drawer__content-wrapper {
        max-height: 100%;
        opacity: 1;
        visibility: visible;
    }

    .drawer__trigger:checked+.drawer__title::after {
        transform: rotate(-45deg);
        transition: 0.25s ease-in-out;
    }

    input[type="checkbox"] {
        display: none;
    }
}