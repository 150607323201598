@import './colours.scss';
@import './anim.scss';

$white-color: #FFFFFF;
$neon-pink-color: #e60073;

@mixin responsive($breakpoint) {
    @if $breakpoint == desktop {
        @media only screen and (min-width: 992px) {
            @content;
        }
    }
    @else if $breakpoint == tablet {
        @media only screen and (max-width: 768px) {
            @content;
        }
    }
    @else if $breakpoint == mobile {
        @media only screen and (max-width: 576px) {
            @content;
        }
    }
}

.app__flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // height: 100vh;
}






.swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}

.swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 300px;

    border-radius: 35px;
    overflow: hidden;
}

.swiper-slide img {
    display: block;
    width: 100%;
}

.table {
    z-index: 4;

    thead tr th {
        background: $beige-colour;
        z-index: 5;
    }
}








.text__new-line {
    // For line break \n:
    // white-space: pre-line;
    // For line break \n and tabs \t:
    white-space: pre-wrap;
}

.text__neon-pink {
    color: $white-color;
    text-shadow: 0 0 10px $white-color, 0 0 20px $white-color, 0 0 30px $neon-pink-color, 0 0 40px $neon-pink-color, 0 0 50px $neon-pink-color, 0 0 60px $neon-pink-color, 0 0 70px $neon-pink-color;
}

.app__hover-with-shadow {
    transition: 0.3s ease-out;

    &:hover {
        box-shadow: 0 0.5em 0.5em -0.4em $green-shade-colour;
        transform: translateY(-0.25em);
    }
}

.app_text__headingWithLine {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #000;
    line-height: 0.1em;
    margin: 10px 0 20px;

    span { 
        background: #fff; 
        padding:0 10px; 
    }
}

/*.app__hover-arrow-button {
    display: inline-block;
    position: relative;
    transition: 0.5s;


    &:after {
        content: '\2192';
        position: absolute;
        opacity: 0;
        top: 7px;
        right: -20px;
        transition: 0.5s;
    }

    &:hover {
        padding-right: 30px;
        padding-left: 12px;
        color: white;
    }

    &:hover:after {
        opacity: 1;
        right: 10px;
    }
}*/

.app__border-bottom {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 2px;
        border-radius: 25px;
        // transform: scaleX(1);
        background: $pink-dark-colour;
        transition: width 0.2s;
    }

    &:hover::before {
        width: 100%;
    }
}

/* Simple CSS3 Fade-in Animation */
// .app__underlineHover {
//     &::after {
//         display: block;
//         left: 0;
//         bottom: -10px;
//         width: 0;
//         height: 2px;
//         background-color: $pink-dark-colour;
//         content: "";
//         transition: width 0.2s;
//     }

//     &:hover {
//         color: $green-shade-colour;
    
//         &::after {
//             width: 100%;
//         }
//     }
// }


.app__bottom-circle {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        bottom: -10px;
        left: calc(50% - 5px);
        height: 10px;
        width: 10px;
        transition: 0.3s;
        border-radius: 25px;
        // transform: scale(0);
        background: #4070f4;
    }
    
    &:hover::before {
        transform: scale(1.2);
    }
}

.app__top-gradientBlend {
    -webkit-mask-image: linear-gradient(to top, black 90%, transparent 100%);
    mask-image: linear-gradient(to top, black 90%, transparent 100%);
}

.app__buttom-gradientBlend {
    -webkit-mask-image: linear-gradient(to bottom, black 90%, transparent 100%);
    mask-image: linear-gradient(to bottom, black 90%, transparent 100%);
}






.dash__action-button,
.action-button {
    background: $green-shade-colour;
    color: white;
    padding: 4px 10px;

    svg {
        color: inherit;
    }
}


.app__icon-with-badge {
    position: relative;

    .badge {
        &[data-quantity="false"] {
            display: none;
        }

        transition: 0.3s ease-in-out;

        position: absolute;
        bottom: 50%;
        left: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        content: '';
        width: 16px;
        height: 16px;
        background: red;
        color: white;
        border-radius: 50%;
        font-size: 0.8rem;
        font-weight: 600;
        z-index: 10;

        animation: keyframes__badge_scale-up 250ms linear forwards;
    }
}






.app__device {
    &-hide-desktop {
        @include responsive(desktop) {
            display: none;
        }
    }

    &-hide-tablet {
        @include responsive(tablet) {
            display: none;
        }
    }

    &-hide-mobile {
        @include responsive(mobile) {
            display: none;
        }
    }
}










.bg__beige-colour {
    background: $beige-colour;
}

.bg__pink-colour {
    background: $pink-new-colour;
}

.bg__green-shade-colour {
    background: $green-shade-colour;
}