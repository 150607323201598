@import '../../res/colours.scss';
@import '../../constants/constants.scss';

.landing__section {
    // background: $pink-new-colour;
    background: #fff;
    // background-image: linear-gradient(to left top, #facee6, #f8b7da, #f7a0cd, #f487c0, #f16db2, #ee73ba, #eb79c2, #e77fca, #e3a3e1, #e3c3f1, #ebe0f9, #fbfbfb);
    // background-image: linear-gradient(to right bottom, #facee6, #f7d8ef, #f6e2f6, #f6ebfa, #f8f4fd, #f8f4fd, #f8f4fd, #f8f4fd, #f6ebfa, #f6e2f6, #f7d8ef, #facee6);
    min-height: calc(100vh - $navbar-height);



    .swiper-gradient {
        position: absolute;
        inset: 0;
        background: linear-gradient(to right, white, transparent, transparent, transparent, transparent, white);
        z-index: 1;
        pointer-events: none;
    }


    .landing__content {
        padding-top: 80px;


        p {
            color: black;
            line-height: 28px;
            // text-align: right;
            animation: slideFromRight 0.8s forwards;
        }


        .landing__subject {
            letter-spacing: 2px;
            // font-size: 14px;
            // color: #717282;
            color: $green-shade-colour;
            opacity: 0;
            animation: slideFromRight 0.5s forwards;
        }

        .landing__title {
            color: $green-shade-colour;
            font-size: 2.5rem;
            font-weight: 900;
            line-height: 2;
            animation: slideFromRight 1.1s forwards;

            span {
                // color: $pink-dark-colour;
            }
        }

        .landing__services {
            margin-top: 20px;

            span {
                // &::before {
                //     background: $green-shade-colour;
                // }

                &:not(:last-child) {
                    &::after {
                        content: ' |';
                    }
                }

            
                color: $green-shade-colour;
                &:first-child {
                    margin-right: 5px;
                }
                &:not(:first-child) {
                    margin: 0 5px;
                }
                &:nth-child(odd) {
                    color: $pink-dark-colour;
                    &::before {
                        background: $green-shade-colour;
                    }                
                }
                &:nth-child(even) {
                    &::before {
                        background: $pink-dark-colour;
                    }
                }
                font-size: 1.3rem;
                transition: 0.3s ease-in-out;

                // &:hover {
                //     object-position: center center;
                //     transform: scale(1.2);
                //     transition: 0.3s ease-in-out;
                // }
                
                // animation: slideUp 0.5s forwards;
                // &:nth-child(1) {
                //     animation-delay: 0.2s;
                // }
                // &:nth-child(2) {
                //     animation-delay: 0.4s;
                // }
                // &:nth-child(3) {
                //     animation-delay: 0.6s;
                // }
                // &:nth-child(4) {
                //     animation-delay: 0.8s;
                // }

                // cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"  width="40" height="40" viewport="0 0 100 100" style="fill:black;"><circle cx="50" cy="50" r="50"/></svg>') 16 16, auto;
                // &:hover {
                //     cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"  width="40" height="40" viewport="0 0 100 100" style="fill:red;"><circle cx="50" cy="50" r="50"/></svg>') 16 16, auto;
                // }

                // opacity: 0;
                // transform: translate(0, 36px);

                // @for $i from 1 to 5 {
                //     &:nth-child(#{$i}) {
                //         opacity: 1;
                //         transform: translate(0, 0);
                //         transition: transform 0.2s ease-out,
                //             opacity 0.2s ease-out;
                //         transition-delay: $i * 0.1s + 0.65s;
                //     }
                // }
            }
        }

        .landing__cta-button {
            color: #fff;
            text-transform: uppercase;
            border-radius: 5px;
            // background: $green-shade-colour;
            background: $pink-dark-colour;
            // background: linear-gradient(to bottom, $pink-dark-colour 0%, $green-shade-colour 100%);
            font-size: 1rem;
            margin-top: 40px;
            transition: all .3s;
            animation: scaleUp 0.5s forwards;

            &:hover {
                box-shadow: 5px 5px 20px rgba($green-shade-colour, 0.5);
                background: darken($pink-dark-colour, 20%);
            }
        }
    }

    .landing__image {
        // width: 20em;

        img {
            object-fit: contain;

        }
    }

    // #scrollArrow a {
    //     position: absolute;
    //     bottom: 50px;
    //     left: 50%;
    //     z-index: 2;
    //     display: inline-block;
    //     -webkit-transform: translate(0, -50%);
    //     transform: translate(0, -50%);
    //     // color: #fff;
    //     font-size: 0;
    //     // font: normal 400 20px/1 'Josefin Sans', sans-serif;
    //     // letter-spacing: .1em;
    //     // text-decoration: none;
    //     transition: opacity .3s;

    //     &:hover {
    //         opacity: .5;
    //     }

    //     span {
    //         position: absolute;
    //         top: 0;
    //         left: 50%;
    //         width: 20px;
    //         height: 20px;
    //         margin-left: -10px;
    //         border-left: 2px solid $green-shade-colour;
    //         border-bottom: 2px solid $green-shade-colour;
    //         -webkit-transform: rotate(-45deg);
    //         transform: rotate(-45deg);
    //         opacity: 0;
    //         -webkit-animation: fadeIn 500ms 3s forwards, bounce 2s infinite;
    //         animation: fadeIn 500ms 3s forwards, bounce 2s infinite;
    //         box-sizing: border-box;
    //     }
    // }


    // @-webkit-keyframes bounce {
    //     0%, 40% {
    //         -webkit-transform: rotate(-45deg) translate(0, 0);
    //     }
    //     20% {
    //         -webkit-transform: rotate(-45deg) translate(-10px, 10px);
    //     }
    // }

    // @keyframes bounce {
    //     0%, 40% {
    //         transform: rotate(-45deg) translate(0, 0);
    //     }
    //     20% {
    //         transform: rotate(-45deg) translate(-10px, 10px);
    //     }
    // }

    // @-webkit-keyframes fadeIn {
    //     from {
    //         opacity: 0;
    //     }

    //     to {
    //         opacity: 1;
    //     }
    // }

    // @-moz-keyframes fadeIn {
    //     from {
    //         opacity: 0;
    //     }

    //     to {
    //         opacity: 1;
    //     }
    // }

    // @keyframes fadeIn {
    //     from {
    //         opacity: 0;
    //     }

    //     to {
    //         opacity: 1;
    //     }
    // }


    @media only screen and (max-width: 992px) {
        .swiper-slide {
            width: 18rem !important;
        }

        .landing__content {
            padding-top: 45px;

            .swiper-slide {
                width: 18rem !important;
            }

            .landing__title {
                font-size: 2rem;
            }

            .landing__services {
                span {
                    font-size: 1.2rem;
                }
            }
        }
    }


    @media only screen and (max-width: 768px) {

        .swiper-slide {
            width: 230px !important;
            height: 24rem !important;
        }

        .landing__content {
            padding-top: 45px;

            .landing__title {
                font-size: 1.6rem;
            }

            p {
                font-size: 0.9rem;
            }

            .landing__services {
                &:first-child {
                    margin-right: 3px;
                }
                &:not(:first-child) {
                    margin: 0 3px;
                }

                span {
                    font-size: 0.9rem;
                }
            }

            .landing__buy-button {
                font-size: 0.9rem;
            }
        }
    }

    @media only screen and (max-width: 576px) {
        .swiper-slide {
            width: 250px !important;
        }

        .landing__content {
            padding-top: 0;
            margin-bottom: 40px;
        }
    }
}