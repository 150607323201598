@import '../../constants/constants.scss';
@import '../../res/colours.scss';
@import '../../res/mixin.scss';

.header {
    position: sticky;
    top: 0;
    background: rgba(white, 0.85);
    margin: 0 auto;
    height: $navbar-height;
    // Ensures the navbar is above the content
    z-index: 5;
    // @include blur(4px);
    transition: 0.3s ease-in-out;



    .indicator__track {
        width: 100%;
        height: 2px;
        background: transparent;
        position: fixed;
        left: 0;
        right: 0;
        top: $navbar-height;
        bottom: 0;
    }
      
    .indicator__bar {
        position: fixed;
        left: 0;
        right: 0;
        top: $navbar-height;
        bottom: 0;
        height: 2px;
        background: $green-shade-colour;
        border-radius: 2px;
        width: 0%;
    }

    .progress-bar {
        position: fixed;
        left: 0;
        right: 0;
        top: $navbar-height;
        bottom: 0;
        height: 2px;
        background: $green-shade-colour;
        border-radius: 2px;
        transform-origin: center;
    }


    .zoomOut & {
        background: white;
        // transform: scale(0.9);
        // opacity: 0.2;
    }


    .app_navbar__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0rem 2rem;
        height: $navbar-height;


        .logo {
            // display: flex;
            // align-items: center;
            // column-gap: 8px;

            img {
                width: 8rem;
            }
        }

        // h2 {
        //     position: relative;

        //     &::before {
        //         content: '';
        //         position: absolute;
        //         bottom: -3px;
        //         left: 0;
        //         height: 3px;
        //         width: 100%;
        //         transition: 0.3s;
        //         border-radius: 25px;
        //         transform: scaleX(0);
        //         background: #4070f4;
        //     }

        //     &:hover::before {
        //         transform: scaleX(1);
        //     }
        // }


        .navigation {

            .navbar-nav--links {
                display: flex;
                align-items: center;
                column-gap: 2.7rem;
                margin-bottom: 0;


                .link-item {
                    position: relative;
                    color: $green-shade-colour;
                    font-weight: 500;
                    cursor: pointer;
                    // text-shadow: 0 1.5px 1.5px rgba($pink-new-colour, 0);
                    transition: 0.3s ease-in-out;
                    

                    &-active {
                        // position: relative;
                        // text-shadow: 0 1.5px 1.5px rgba($pink-new-colour, 1);
                        color: inherit;

                        &::before {
                            background: $green-shade-colour !important;
                            animation: slideUp 0.5s ease-out forwards;
                        }
                    }
                }
            }
        }


        .avatar_icon-actions--overlay {
            position: absolute;
            top: 0;
            left: 0;
            height: 100vh;
            right: 0;
            background: transparent;
        }

        .navbar-nav--icons {
            display: flex;
            align-items: center;
            column-gap: 1.2rem;


            .profile {
                position: relative;

                img {
                    width: 30px;
                    height: 30px;

                    &:hover {
                        cursor: pointer;
                    }
                }

                .avatar_icon {
                    position: relative;


                    &-actions {
                        position: absolute;
                        top: 100%;
                        right: -50px;
                        width: 150px;
                        z-index: 10;
                        padding: 5px 10px;
                        // display: flex;
                        display: none;
                        align-items: center;
                        flex-direction: column;
                        background: $pink-new-colour;
                        line-height: 30px;
                        margin-top: 20px;


                        border-radius: 5px;
                        border: 5px solid white;
                        box-shadow: 0px 0px 20px rgba($green-shade-colour, 0.5);
                        overflow: hidden;


                        a {
                            width: 100%;
                            // background: green;
                            display: flex;
                            justify-content: center;

                            &:not(:last-child) {
                                border-bottom: 2px solid rgba(lightgray, 1);
                            }
                        }


                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }

            span {
                width: 1.5rem;
                height: 1.3rem;

                svg {
                    width: 1.4rem;
                    height: 1.4rem;
                }
            }

            .basket_icon,
            .wishlist_icon // .avatar_icon

                {
                position: relative;
            }

            .basket_icon,
            .wishlist_icon,
            .avatar_icon
            {
                pointer-events: none;
                visibility: hidden;

                &:hover {
                    cursor: pointer;
                }
            }

            .badge {
                &[data-quantity="false"] {
                    display: none;
                }
                position: absolute;
                bottom: 50%;
                left: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                content: '';
                width: 16px;
                height: 16px;
                background: red;
                color: white;
                border-radius: 50%;
                font-size: 0.8rem;
                font-weight: 600;
                z-index: 3;
                transition: 0.3s ease-in-out;

                animation: keyframes__badge_scale-up 250ms linear forwards;
            }
        }
    }




    &--scroll {
        background: rgba($green-shade-colour, 0.95);

        box-shadow: 0 0 30px rgba(black, 0.85);


        .zoomOut & {
            background: $green-shade-colour;
        }



        .indicator__bar {
            background: $pink-new-colour;
        }
        .progress-bar {
            background: $pink-new-colour;
        }

        .app_navbar__wrapper {
            .navigation {
                .navbar-nav--links {

                    .link-item {
                        color: $pink-new-colour;

                        &-active {
                            // border-bottom: solid 3px $pink-new-colour;
                            color: $pink-new-colour;


                            &::before {
                                background: $pink-new-colour !important;
                            }
                        }
                    }
                }
            }


            .navbar-nav--icons {

                .basket_icon,
                .wishlist_icon,
                .avatar_icon,
                .menu_icon {

                    svg {
                        color: $pink-new-colour;
                    }
                }
            }
        }
    }






    /* ===========================
   ====== Media Query's ====== 
   =========================== */
    @media only screen and (max-width: 992px) {
        .app_navbar__wrapper .navigation .navbar-nav--links {
            column-gap: 2.3rem;
            margin-right: 30px;
        }
    }

    @media only screen and (max-width: 768px) {
        .app_navbar__wrapper .navigation .navbar-nav--links {
            column-gap: 1.9rem;
            margin-right: 30px;
        }

        .app_navbar__wrapper .navbar-nav--icons .profile .avatar_icon-actions {
            right: 0px;
        }
    }


    @media only screen and (max-width: 576px) {
        .app_navbar__wrapper .navbar-nav--icons .profile .avatar_icon-actions {
            right: 0px;
        }
    }
}

.show__profile-actions {
    display: flex;
}


@keyframes keyframes__badge_scale-up {
    0% {
        transform: scale(0);
    }

    80% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}