.avatar {
    position: relative;
    // background: green;
    border-radius: 50%;
    overflow: hidden;

    svg {
        width: 100% !important;
        height: 100% !important;
    }

    img {
        object-fit: cover;
        object-position: center;
        width: 100% !important;
        height: 100% !important;
    }
}