@import '../../res/colours.scss';
@import '../../constants/constants.scss';

:root {
    --svg_size: 30px;
}

.basket__section {
    // min-height: calc(100vh - $navbar-height);


    h2 {
        animation: fadeIn 0.8s ease-out forwards;
    }

    .buy-button {
        color: white;
        text-transform: uppercase;
        border-radius: 5px;
        background: $pink-dark-colour;
        font-size: 1rem;
        // margin-top: 40px;


        &:hover {
            color: white;
            background: darken($pink-dark-colour, 20%);
        }
    }


    .table {

        tr td img {
            width: 50px !important;
            height: 50px !important;
            object-fit: cover;
        }

        td, th {
            // color: $green-shade-colour;
        }

        td {
            margin: 0 auto;
            // display: flex;
            // flex-direction: row;
            // justify-content: center;
        }

        .product__increase,
        .product__decrease {
            width: 25px;
            height: 25px;
            background: transparent;
            color: black;
            padding: 0px;

         
            svg {
                width: 100%;
                height: 100%;
                padding: 5px;
                color: $green-shade-colour;
            }
        }

        .product__delete {
            color: black;
            background: transparent;
            border: none;
            outline: none;
            width: var(--svg_size);
            height: var(--svg_size);
            padding: 0px;

            &:hover {
                cursor: pointer;
            }

            svg {
                width: 100%;
                height: 100%;
                padding: 5px;
                color: black;
            }
        }
    }

    .product-image {
        width: 40px;
        height: 40px;
    }

    // .basket__checkout,
    // .basket__shop {
    //     color: white;
    //     background: black;

    // }



    @media only screen and (max-width: 768px) {
        h2 {
            font-size: 1.5rem;
        }
    }
    @media only screen and (max-width: 576px) {
        h2 {
            font-size: 1.25rem;
        }
    }

}