@import '../../../../res/anim.scss';
@import '../../../../res/colours.scss';

#pageWrapper {
    width: 100%;
    padding: 20px 30px;


    form {
        @for $i from 1 to 7 {
            &:nth-child(#{$i}) {
                $delay: 0.4s+($i * 0.2s);

                // opacity: 0;
                // -webkit-animation: fadeIn ease-in 1s $delay forwards;
                // -moz-animation: fadeIn ease-in 1s $delay forwards;
                // animation: fadeIn ease-in 1s $delay forwards;

                // -webkit-animation-duration: 1s;
                // -moz-animation-duration: 1s;
                // animation-duration: 1s;

                // -webkit-animation-delay: 0.4s + ($i * 0.2s);
                // -moz-animation-delay: 0.4s + ($i * 0.2s);
                // animation-delay: 0.4s + ($i * 0.2s);
            }
        }

        .submitButton {
            background: $pink-new-colour;
            // border: none;
            color: black;
            padding: 15px 80px;
            // text-align: center;
            // text-decoration: none;
            display: inline-block;
            text-transform: uppercase;
            // font-size: 13px;
            -webkit-box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
            box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
            // -webkit-border-radius: 5px 5px 5px 5px;
            // border-radius: 5px 5px 5px 5px;
            margin: 5px 20px 20px 20px;
        }
    }

    #formFooter {
        background-color: #f6f6f6;
        border-top: 1px solid #dce8f1;
        padding: 20px;
        text-align: center;
        -webkit-border-radius: 0 0 10px 10px;
        border-radius: 0 0 10px 10px;
    }
}