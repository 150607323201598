@import '../../../../res/global.scss';
@import '../../../../res/colours.scss';


$button-colours:
    #0072b1,
    red,
    $green-shade-colour,
;

:root {
    --icon_svg_size: 2rem;
    --icon_svg_size-half: calc((var(--icon_svg_size) / 2) * -1);
}

.product__card {
    position: relative;
    border-radius: 15px;
    border: 5px solid white;
    box-shadow: 0px 0px 5px rgba($green-shade-colour, 0.5);
    width: 19rem;
    height: 25rem;
    overflow: hidden;
    z-index: 1;
    padding: 0 0rem;
    background: white;
    transition: .5s ease-out;


    &:hover {
        cursor: pointer;
        border: 5px solid rgba($pink-new-colour, 1);
        box-shadow: 0px 0px 30px 1px rgba($green-shade-colour, 0.5);
        z-index: 3;


        // .product__image-wrapper {

        //     img {
        //         // transform: scale(1.2);
        //     }
        // }
        transform: scale(1.05);
    }

    // &:active {
    //     transform: scale(0.95);
    // }


    .product__image-wrapper {
        position: relative;
        height: 15rem;

        .product__image {
            border-bottom-right-radius: 30%;
            height: 100%;
            overflow: hidden;
        }

        img {
            object-fit: cover;
            object-position: center center;
            width: 100%;
            height: 100%;

            transition: .2s ease-out;
        }

        .buttons {
            position: absolute;
            bottom: var(--icon_svg_size-half);
            right: 0;
            margin-right: 10px;
            z-index: 2;


            button {
                display: flex;
                margin: 0 auto;
                position: relative;
                // width: var(--icon_svg_size);
                // height: var(--icon_svg_size);
                border-radius: 50%;
                border: 1px solid rgba($green-shade-colour, 1);
                background: $pink-new-colour;
                padding: 5px;

                // svg {
                //     width: 100%;
                //     height: 100%;
                //     color: $pink-new-colour;
                // }
            }


            // @for $i from 1 through length($button-colours) {
            //     :nth-child(#{length($button-colours)}n+#{$i}) {
            //         background: nth($button-colours, $i);

            //         svg {
            //             background: transparent;
            //             color: white;
            //         }
            //     }
            // }
        }
    }

    .product__info {
        color: inherit;
        padding: 0 0.3rem;


        .product__title {
            font-size: 1rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            font-size: 1.2rem;
            font-weight: 600;
            margin-top: 15px;
        }

        .product__detail {
            color: gray;
        }

        span {
            font-size: 0.85rem;
        }

        a {

            &:hover {
                color: inherit;
            }
        }
    }

    .product_card-bottom {
        position: relative;

        .price {
            font-size: 1.2rem;
            font-weight: 500;
            font-weight: bold;
        }

        .sale-price {
            position: relative;
            font-size: 1rem;
            // font-weight: 300;
            color: lightgray;

            &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                border-top: 3px solid red;
                border-radius: 20px;
                transform: rotate(-20deg);
            }
        }

        .add-to-basket-button {
            background: $green-shade-colour;
            color: white;
        }

        span {


            svg {
                width: 20px;
                height: 20px;
                padding: 5px;
                background: $green-shade-colour;
                color: white;
                border-radius: 50%;
            }
        }
    }




    /* ===========================
   ====== Media Query's ====== 
   =========================== */
    @media only screen and (max-width: 992px) {

        // .product__info h3 a {
        //     font-size: 1.1rem;
        // }
    }

    @media only screen and (max-width: 768px) {

        // .product__info h3 a {
        //     font-size: 1rem;
        // }

        .product_card-bottom span svg {
            // width: 30px;
            // height: 30px;
        }
    }

    @media only screen and (max-width: 576px) {
        margin: 0px 40px;
    }
}