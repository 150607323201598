@import '../../res/colours.scss';

.filter__widget {
    .category-header {
        font-weight: 700;
        margin-left: 10px;
        margin-bottom: 20px;
        text-decoration: underline;
    }


    select {
        padding: 5px 20px;
        border: 1px solid grey;
        border-radius: 5px;
        background: $pink-new-colour;

        option {
            // font-size: 0.9rem;
        }

        &:focus {
            outline: none !important;
        }

        &:hover {
            cursor: pointer;
        }
    }
}

.search__box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $pink-new-colour;
    border: 1px solid grey;
    border-radius: 5px;
    padding-right: 10px;
    cursor: pointer;


    input {
        width: 100%;
        border: none;
        padding: 5px 20px;
        background: $pink-new-colour;


        &:focus {
            outline: none !important;
        }

        &:hover {
            cursor: pointer;
        }
    }

    span {

        svg {
            width: 1.4rem;
            height: 1.4rem;
        }
    }
}


/* ===========================
   ====== Media Query's ====== 
   =========================== */
@media only screen and (max-width: 768px) {
    .category-header {
        text-align: center;
    }

    .search__box {
        margin-bottom: 30px;

        select {
            padding: 7px 20px;
            font-size: 0.9rem;
        }
    }
}