@import '../../res/colours.scss';

.contact__section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 100px;
    background: #f8f8f8;
    // background-image: linear-gradient(to right bottom, #facee6, #f7d8ef, #f6e2f6, #f6ebfa, #f8f4fd, #f8f4fd, #f8f4fd, #f8f4fd, #f6ebfa, #f6e2f6, #f7d8ef, #facee6);


    // .map-content {
    //     position: relative;
    //     inset: 0;
    //     display: flex;
    //     transition: .5s ease-out;
    //     width: 100%;
    //     height: 400px;
    //     margin-top: 20px;
    //     border-radius: 5px;
    //     overflow: hidden;
    //     background: red;
    // }

    .maps {
        position: relative;
        inset: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        text-align: center;
        // overflow: hidden;


        .scroller {
            position: relative;
            display: grid;
            grid-auto-flow: column;
            margin: 0 auto;
            width: 100%;
            height: 400px;
            overflow-y: hidden;
            overscroll-behavior-x: contain;
            scroll-snap-type: x mandatory;
            scroll-behavior: smooth;

            &::-webkit-scrollbar {
                display: none;
            }

            scrollbar-width: none;

            .item {
                display: flex;
                justify-content: flex-start;
                scroll-snap-align: center;
                width: 85vw;
                // height: 90%;
                background: white;
                margin: 0 10px;
                pointer-events: all;
            }
        }
    }

    .submitButton {
        background: $pink-new-colour;
        color: black;
        padding: 15px 80px;
        display: inline-block;
        text-transform: uppercase;
        // font-size: 13px;
        -webkit-box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
        box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
        // -webkit-border-radius: 5px 5px 5px 5px;
        // border-radius: 5px 5px 5px 5px;
        margin: 20px;
    }


    @media only screen and (max-width: 768px) {
        padding: 50px 50px;

        .maps {
            .scroller {
                .item {
                    width: 75vw;
                }
            }
        }
    }

    @media only screen and (max-width: 576px) {}
}