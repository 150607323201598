@import '../../res/colours.scss';
@import '../../constants/constants.scss';

.experts__section {
    // background: $pink-new-colour;
    background: #fff;
    
    .list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        // overflow-y: hidden;
        padding-top: 10px;
        padding-bottom: 30px;

        gap: 30px;
    }
}