.loading-screen {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(black, 0.8);
    z-index: 9999;

    display: flex;
    justify-content: center;
    align-items: center;
}