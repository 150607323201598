@import '../../res/colours.scss';

.about__section {
    background: $pink-new-colour;

    h2 {
        color: #fff;
    }

    .featureRow-Articles {
        text-align: left;

        span {
            margin: 1rem 0;
        }
    
        .feature-btn {
            background: $pink-dark-colour;

            box-shadow: 0px 0px 10px rgba($green-shade-colour, 0.5);
            color: #fff;
            // font-weight: bold;
    
            &:hover {
                box-shadow: 5px 5px 20px rgba($green-shade-colour, 0.5);
                background: darken($pink-dark-colour, 20%);
            }
        }
    }
}