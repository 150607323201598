@import '../../../res/colours.scss';


.tint-background {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), transparent, rgba(0, 0, 0, 1));
        opacity: .6; 
    }
}

.timer__wrapper {
    background: $green-shade-colour;
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    height: 300px;
    justify-content: flex-end;

    .counter__image {

        img {
            width: 90% !important;
            height: 90% !important;
            object-fit: contain;
        }
    }

    .countdown-col {
        // display: flex;
        // flex-direction: column;
        // justify-items: center;
        // text-align: center;
        // margin: 0.7rem auto 0;

        .store__button {
            color: $green-shade-colour;
            text-transform: uppercase;
            border-radius: 5px;
            background: white;
            // font-size: 1rem;
            font-weight: 600;
            margin-top: 20px;
            // width: fit-content;

            a {
                color: inherit;
            }
        }
    }






    /* ===========================
   ====== Media Query's ====== 
   =========================== */
    @media only screen and (max-width: 992px) {
        justify-content: center;

        .countdown-col {
            text-align: center;

            .clock__top-content {
                text-align: center;

                h4 {
                    font-size: 1.1rem !important;
                }

                h3 {
                    font-size: 1.4rem !important;
                }
            }
        }

        .counter__image {
            display: none;
        }
    }
}