.clock__wrapper {
    // background: green;
    margin: 0 auto;

    .clock__data {

        h1 {
            font-size: 1.5rem;
        }

        h5 {

            font-size: 1rem;
        }
    }
}

@media only screen and (max-width: 992px) {
    .clock__wrapper {
        justify-content: center;
        text-align: center;


        .clock__data {

            h1 {
                font-size: 1.1rem !important;
            }

            h5 {

                font-size: 0.9rem;
            }
        }
    }
}