@import '../../res/colours.scss';

.footer {
    position: relative;
    padding-top: 50px;
    padding-bottom: 20px;
    background: $pink-new-colour;



    // .hero {
    //     width: 100%;
    //     height: 100vh;
    //     background: #f1f9ff;
    //     display: flex;
    //     align-items: center;
    //     flex-direction: column;
    //     justify-content: center;
    // }
    // .socials-links {
    //     display: flex;


    //     a {
    //         width: 80px;
    //         height: 80px;
    //         text-align: center;
    //         text-decoration: none;
    //         color: #000;
    //         box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.05);
    //         margin: 0 30px;
    //         border-radius: 50%;
    //         position: relative;
    //         overflow: hidden;
    //         transition: transform 0.5s;

    //         .fab {
    //             font-size: 30px;
    //             line-height: 80px;
    //             position: relative;
    //             z-index: 10;
    //             transition: color 0.5s;
    //         }

    //         &::after {
    //             content: '';
    //             width: 100%;
    //             height: 100%;
    //             top: -90px;
    //             left: 0;
    //             background: #000;
    //             // background: linear-gradient(-45deg, #ed1c94, #ffec17);
    //             background: $pink-dark-colour;

    //             position: absolute;
    //             transition: 0.5s;
    //         }
    //     }

    //     a:hover::after {
    //         top: 0;
    //     }

    //     a:hover .fab {
    //         color: #fff;
    //     }

    //     a:hover {
    //         transform: translateY(-10px);
    //     }
    // }


    

    .logo {
        img {
            width: 15rem;
        }
    }

    .footer__text {
        padding-top: 0.8rem;
        line-height: 30px;
    }

    .quick__links-title {
        margin-bottom: 0;
        font-size: 1.1rem;
        font-weight: 600;
    }

    .social-links {
        position: absolute;
        bottom: 54px;
        // left: 0;

        a {
            color: $pink-dark-colour;
            font-size: 16px;
            border: 1px solid rgba(128, 128, 128, 0.5);
            border-radius: 50%;
            padding: 3px;
            -webkit-transition: all 300ms ease-in;
            transition: all 300ms ease-in;

            &:not(:last-child) {
                margin-right: 10px;
            }

            i {
                width: 28px;
                height: 35px;
                text-align: center;
            }
        }

        a:hover,
        a:focus {
            // color: #fff;
            // background-color: $pink-dark-colour;
            border: 1px solid #fff;
        }
    }

    .footer__quick-links {
        margin-top: 3.4rem;

        ul,
        li {
            background: transparent !important;
            color: inherit;

            a {
                color: inherit;

                // &:hover:after,
                // &:focus:after {
                //     width: 100%;
                //     left: 0;
                // }

                // &:after {
                //     top: 30px;
                //     content: "";
                //     display: block;
                //     height: 2px;
                //     left: 50%;
                //     position: absolute;
                //     width: 0;
                //     background: #ef4b42;

                //     -webkit-transition: width 0.3s ease 0s, left 0.3s ease 0s;
                //     transition: width 0.3s ease 0s, left 0.3s ease 0s;
                // }
            }
        }
    }

    .footer__contact {

        ul,
        li {
            color: inherit;
        }

        li {
            span {
                color: inherit;
                font-size: 1.2rem;
            }

            p {
                color: inherit;
                font-size: 0.9rem;
            }
        }

        svg {
            color: inherit;
            width: 20px;
            height: 20px;
        }
    }

    .footer__subscribe {

        .sub-text {
            font-size: 0.8rem;
            color: gray;
        }

        #subscribe {
            margin: 0;
            font-size: 12px;

            input#subscriber-email {
                outline: none;
                padding: 8px;
                background: white;
                border: 1px solid $pink-dark-colour;
                color: $green-shade-colour;
                border-radius: 4px 0px 0px 4px;
                width: 70%;
                transition: 0.3s ease-out;

                &::-webkit-input-placeholder {
                    color: $green-shade-colour;
                }

                &:-ms-input-placeholder {
                    color: $green-shade-colour;
                }

                &::-ms-input-placeholder {
                    color: $green-shade-colour;
                }

                &::placeholder {
                    color: $green-shade-colour;
                }

                // &:not(:invalid), &:focus {
                //     transition: 0.3s ease-out;

                //     #btn-scribe {
                //         opacity: 0.5;
                //     }
                // }
            }

            #btn-scribe {
                margin-left: -4px;
                border: 1px solid $pink-dark-colour;
                border-radius: 0px 4px 4px 0;
                padding: 8px 5px;
                background: $pink-dark-colour;
                color: #fff;
                cursor: pointer;
                transition: 0.3s ease-out;
            }

            &:hover {

                input#subscriber-email {
                    border: 1px solid darken($pink-dark-colour, 20%);
                }

                #btn-scribe {
                    background: darken($pink-dark-colour, 20%);
                }
            }
        }
    }

    .footer__copyright {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &--text {
            color: inherit;
            font-size: 0.9rem;
            margin-top: 10px;
            // text-align: center;
        }

        .ABT {
            width: 90px;
            object-fit: contain;
            object-position: center center;
            filter: grayscale(100%)
        }
    }



    /* ===========================
   ====== Media Query's ====== 
   =========================== */
    @media only screen and (max-width: 992px) {
        .footer__subscribe {
            #subscribe {
                input#subscriber-email {
                    width: 50%;
                }
            }
        }


        // .quick__links-title {
        //     margin-bottom: 5px;
        // }   
        .footer__quick-links {
            margin-top: 0;
        }

        .quick__links-title {
            margin-bottom: 0;
            margin-top: 0.6rem;
        }

        .footer__copyright {
            flex-direction: column;
            text-align: center;
            align-items: center;


            &--text {
                font-size: 0.7rem;
            }

            .ABT {
                margin: 10px 0;
            }

        }
    }

    @media only screen and (max-width: 768px) {
        .social-links {
            margin-bottom: 60px;
            align-self: center;
        }

        .footer__copyright {
            margin-top: 4rem;
        }
    }

    @media only screen and (max-width: 576px) {}
}