@import '../../res/colours.scss';
@import '../../res/mixin.scss';

.location__banner {
    // .neon {
    //     position: relative;
    //     overflow: hidden;
    //     filter: brightness(200%);
    //     mix-blend-mode: lighten;
    //     /* here */
    // }

    // .text {
    //     background-color: black;
    //     color: white;
    //     font-size: 22px;
    //     font-weight: bold;
    //     font-family: sans-serif;
    //     text-transform: uppercase;
    //     position: relative;
    //     user-select: none;

    //     &::before {
    //         content: attr(data-text);
    //         position: absolute;
    //         color: white;
    //         filter: blur(0.02em);
    //         mix-blend-mode: difference;
    //     }
    // }

    // .gradient {
    //     position: absolute;
    //     background: linear-gradient(45deg, #00f7ff, #f545d7, #0085ff, #6945f5, #005aff);
    //     top: 0;
    //     left: 0;
    //     right: 0;
    //     bottom: 0;
    //     mix-blend-mode: multiply;
    // }

    // .spotlight {
    //     position: absolute;
    //     top: -100%;
    //     left: -100%;
    //     right: 0;
    //     bottom: 0;
    //     background: radial-gradient(circle, white, transparent 25%) center / 25% 25%, radial-gradient(circle, white, black 25%) center / 12.5% 12.5%;
    //     animation: light 5s linear infinite;
    //     mix-blend-mode: color-dodge;
    // }

    // @keyframes light {
    //     to {
    //         transform: translate(50%, 50%);
    //     }
    // }














    // background: linear-gradient(rgba(0, 0, 0, 0.562), rgba(0, 0, 0, 0.562)),
    //     url("../../assets/images/BannerImage.png") no-repeat center center;
    // background-size: cover;
    position: relative;
    display: flex;
    flex: 1;
    // align-items: flex-end;
    justify-content: flex-start;
    margin: 0 auto;
    transition: .5s ease-out;

    // &:hover {

    //     .map-content {
    //         filter: brightness(100%);
    //     }

    //     .module-border-wrap {
    //         background: rgba(166, 166, 166, 0.75);
    //     }
    // }

    // &::after {
    //     content: '';
    //     position: absolute;
    //     inset: 0;
    //     background: linear-gradient(to bottom, rgba(transparent, 0) 0%, rgba(transparent, 0) 80%, rgba(#facee6, 1) 100%),
    // }

    .map-content {
        position: absolute;
        inset: 0;
        display: flex;
        transition: .5s ease-out;
        // filter: brightness(50%);

        img {
            object-position: top;
        }
    }

    .module-border-wrap {
        position: relative;
        width: 30rem;
        // border-radius: 10px 100px / 120px;
        border-radius: 10px;
        border: 10px solid $pink-new-colour;
        overflow: hidden;
        // border: 10px solid transparent;
        transition: .5s ease-out;

        // background: rgba(255, 255, 255, 0.45);
        background: rgba(166, 166, 166, 0.75);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        @include backdropBlur(13px);
        // border-radius: 10px;


        .module {
            // background: white;
            color: white;
            padding: 1.5rem 3.5rem;

            h2 {
                font-size: 1.8rem;
                border-bottom: 1px solid $pink-new-colour;
                width: 100%;
                margin-bottom: 10px;
                color: $green-shade-colour;
            }

            .schedule {
                &[data-open="true"] {
                    color: white;
                }

                label {
                    font-size: 0.9rem;
                }

                .openColour {
                    color: black;
                    font-weight: bolder;
                    text-decoration: underline;
                    text-transform: uppercase;
                }

                .closedColour {
                    color: red;
                    font-weight: normal;
                }

                .openLabel {
                    display: flex;
                    justify-content: center;
                    color: red;
                    text-transform: uppercase;
                    font-size: 1.5rem;
                    font-weight: bolder;
                    margin-top: 1rem;

                    &[data-open="true"] {
                        color: #000;
                    }
                }
            }

        }
    }
}


@media only screen and (max-width: 768px) {
    .location__banner {

        .module-border-wrap {
            .module {
                padding: 24px;

                h2 {
                    font-size: 1.3rem;
                }

                .schedule {
                    label {
                        font-size: 0.9rem;
                    }
                }
            }

        }
    }
}