@import '../../../constants/constants.scss';
@import '../../../res/colours.scss';

.dash-sidebar {
    position: sticky;
    top: 0;
    width: $sidebar-width-desktop;
    height: calc(100vh + $navbar-height);
    background: $green-shade-colour;
    // background: $pink-new-colour;
    // Ensures the navbar is above the content
    z-index: 5;



    .dash_sidebar__wrapper {
        // display: flex;
        // flex: 1;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
        // padding: 0rem 2rem;
        width: 100%;
        height: 100vh;



        .avatar_wrapper {
            display: flex;
            // flex: 1;
            align-items: center;
            justify-content: flex-start;
            flex-direction: row;
            width: 100%;
            overflow: hidden;
            padding: 1rem 0.5rem;
            margin-bottom: 20px;
            gap: 5px;


            .profile-photo {
                width: 3rem;
                border: 2px solid $pink-new-colour;
            }

            .avatar__details {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: white;


                .title {
                    font-size: 12px;
                    font-weight: 700;
                }

                .sub-title {
                    font-size: 10px;
                }
            }
        }


        .navigation {
            width: 100%;

            svg {
                width: 25px;
                height: 25px;
            }


            .sidebar-nav--links {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                row-gap: 2rem;
                width: 100%;
                padding: 0;

                .link-item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: 0.3s ease-in-out;

                    svg {
                        color: white;
                        object-position: center center;
                        transform: scale(1);
                        transition: 0.3s ease-in-out;
                    }

                    &-active {

                        svg {
                            cursor: pointer;
                            color: $pink-new-colour;
                            transform: scale(1.2);
                        }
                    }
                }
            }
        }
    }






    /* ===========================
   ====== Media Query's ====== 
   =========================== */
    @media only screen and (max-width: 992px) {}

    @media only screen and (max-width: 768px) {
        width: $sidebar-width-mobile;
    }

    @media only screen and (max-width: 576px) {
        width: $sidebar-width-mobile;

        .dash_sidebar__wrapper {
            .navigation {

                svg {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}