.image__banner {
    background:
        linear-gradient(rgba(white, 1) 0%, rgba(#facee6, 0.362) 50%, rgba(white, 1) 100%),
        url("../../../assets/images/Shop.jpeg") no-repeat center center/cover;
    background-position: 0 20%;
    padding: 70px 0px;
    text-align: center;
    
    h1 {
        color: #fff;
        font-weight: 600;
        opacity: 0;
        animation: slideUp 0.8s ease-out forwards;
    }

    h6 {
        color: #fff;
        opacity: 0;
        animation: slideUp 0.8s 0.4s ease-out forwards;

        // &::before {
        //     content: '';
        //     position: absolute;
        //     bottom: 0;
        //     left: 0;
        //     width: 100%;
        //     height: 2px;
        //     border-radius: 25px;
        //     // transform: scaleX(1);
        //     background: #fff;
        // }
    }
}


@media only screen and (max-width: 768px) {
    .image__banner {
        padding: 70px 0px;
        background-position: 0 10%;

        h1 {
            font-size: 1.4rem;
        }
    }
}

@media only screen and (max-width: 576px) {}