@import './res/global.scss';
@import './res/colours.scss';
@import './constants/constants.scss';

/* google fonts */
@import url('https://fonts.googleapis.com/css2?family=Gotu&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Display&display=swap');

// ProductDetails icons
@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://use.fontawesome.com/releases/v6.1.1/css/all.css');

:root {
  /* Transitions for navbar link selection. */
  --mainTransition: 0.25s ease-in-out;
  /* Transitions for mode (dark/light) selection. */
  --modeTransition: 0.25s ease-in-out;
}


body {
  font-family: 'Gotu', sans-serif;
  // font-family: 'Noto Serif Display', serif;
}

*,
* *::before,
* *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;


  -webkit-tap-highlight-color: transparent;
  outline: none;
}

ul {
  list-style: none;
}

a {
  color: unset;
  text-decoration: none;
}

a:hover,
a:focus {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
}

h1,
h2 {
  font-size: $heading-fontSize;
}

.new-line {
  // For line break \n:
  // white-space: pre-line;
  // For line break \n and tabs \t:
  white-space: pre-wrap;
}

button {
  background: transparent;
  border: none;


  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none !important;
    box-shadow: none;
  }

  &[disabled] {
    opacity: 0.3;

    &:hover {
      cursor: default;
      transform: scale(1);
    }
  }
}

input,
textarea {

  &:focus {
    outline: none;
  }
}

.table {
  position: relative;
  border-radius: 5px;
  display: block;
  display: table;
  width: 100%;
  overflow: auto;


  @media only screen and (max-width: 992px) {}

  @media only screen and (max-width: 768px) {
    display: block;
  }

  @media only screen and (max-width: 576px) {
    display: block;
  }
}


svg {
  width: 100%;
  height: 100%;
  color: black;
}

img {
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
}

section {
  padding: 60px 30px;
  transition: 500ms ease-out;

  .zoomOut & {
    pointer-events: none;
    transform: scale(0.9);
    opacity: 0.2;
    // transition: opacity 0.2s 0.41s ease-out;
  }


  @media only screen and (max-width: 992px) {}

  @media only screen and (max-width: 768px) {
    padding: 45px 25px;
  }

  @media only screen and (max-width: 576px) {
    padding: 30px 20px;
  }
}

.disabled-link {
  pointer-events: none;
}

table tbody tr td {
  @media only screen and (max-width: 992px) {}

  @media only screen and (max-width: 768px) {
    font-size: 15px;
  }

  @media only screen and (max-width: 576px) {
    font-size: 12px;
  }
}