@keyframes scaleUp {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes slideUp {
    0% {
        opacity: 0;
        transform: translateY(100px);
    }
    70% {
        opacity: 0;
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideDown {
    0% {
        opacity: 0;
        transform: translateY(-100px);
    }
    70% {
        opacity: 0;
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideFromRight {
    0% {
        opacity: 0;
        transform: translateX(200px);
    }
    70% {
        opacity: 0;
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes rotate360 {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
        // @include blur(0px);
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
        // @include blur(4px);
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
        // @include blur(0px);
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
        // @include blur(4px);
    }
}

@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        // @include blur(0px);
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
        // @include blur(4px);
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        // @include blur(0px);
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
        // @include blur(4px);
    }
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeOut {
    to {
        opacity: 0;
    }
}

@-moz-keyframes fadeOut {
    to {
        opacity: 0;
    }
}

@keyframes fadeOut {
    to {
        opacity: 0;
    }
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}