@import '../../res/colours.scss';
@import '../../constants/constants.scss';

.app__notFound {
    @media screen and (max-width: 900px) {
        // top | right | bottom | left
        // padding: $navbar-height/2 5px 20px 5px;
    }

    min-height: calc(100vh - $navbar-height);
    background: white;
    padding: 0 2rem;
    background: linear-gradient(to bottom, white, $pink-new-colour);
    z-index: 1;


    .main-404 {
        position: relative;
        // background: green;
        // height: ;

        h1 {
            @media screen and (max-width: 900px) {
                font-size: 10em;
            }

            font-size: 15em;
            color: gray;

            animation: flash-text-shadow 5s 1s infinite;
        }

        .not-found {
            position: absolute;
            bottom: 10px;
            font-size: 2em;
            font-weight: bolder;
            color: $green-shade-colour;

            @media screen and (max-width: 900px) {
                font-size: 1.8em;
                bottom: 0;
            }
        }
    }

    .information {
        @media screen and (max-width: 900px) {
            font-size: 1.3em;
        }

        font-size: 1.5em;
        text-align: center;
        margin-bottom: 20px;
        color: black;
    }

    .notfound__buttons {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }

    .notfound__cta-button {
        color: white;
        text-transform: uppercase;
        border-radius: 5px;
        background-color: $pink-dark-colour;
        font-size: 1rem;

        &:hover {
            background-color: darken($pink-dark-colour, 20%);
        }
    }
    .notfound__cta-contactButton {
        color: black;
        background-color: white;
        border: 1px solid $pink-dark-colour;

        &:hover {
            color: black !important;
            background-color: darken(white, 5%);
        }
    }
}




@keyframes flash-text-shadow {

    0%,
    100% {
        text-shadow: 0 2px 5px rgba($pink-new-colour, 0);
    }

    50% {
        text-shadow: 0 6px 10px rgba($pink-new-colour, 1);
    }



    // from {
    //     text-shadow: 0 2px 5px rgba($pink-new-colour, 0);
    // }

    // to {
    //     text-shadow: 0 12px 15px rgba($pink-new-colour, 1);
    // }
}