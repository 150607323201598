@import '../../../res/colours.scss';

.motion__button {
    color: white;
    background: black;
    padding: 8px 20px;
    border-radius: 5px;
    min-width: 35px;
    text-align: center;
    // display: flex;
    // align-items: center;
    // font-weight: 600;
    // margin-top: 20px;

    a {
        // color: inherit;
    }

    svg {
        width: 100%;
        height: 100%;
    }



    /* ===========================
   ====== Media Query's ====== 
   =========================== */
    @media only screen and (max-width: 992px) {}

    @media only screen and (max-width: 768px) {
        font-size: 1rem;
        // padding: 4px 10px;
    }

    @media only screen and (max-width: 576px) {
        font-size: 0.8rem;
        // padding: 4px 10px;
    }
}