// @import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);
@import '../../../res/mixin.scss';
@import '../../../res/colours.scss';

$icon: 45px;

.gallery-container {
    // font-family: FontAwesome;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    // @include blur(4px);

    position: fixed;
    inset: 0;
    z-index: 5;


    &.hide {
        visibility: hidden;
    }



    .gradient {
        height: 100%;
        width: 100%;
        top: 0;
        background: linear-gradient(to top, rgba(black, 0.9) 0%, transparent 50%, transparent 100%);
        color: white;
        z-index: 1;
        pointer-events: none;
    }


    img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: contain;
        // border-radius: 10px;

        // border: 5px solid rgba($pink-new-colour, 1);
        // box-shadow: 0px 0px 30px 1px rgba($green-shade-colour, 0.5);
    }

    .next,
    .prev {
        cursor: pointer;
        position: absolute;
        top: 0;
        top: 50%;
        width: auto;
        margin-top: -22px;
        padding: 16px 20px;
        color: white;
        font-weight: bold;
        font-size: 32px;
        transition: 0.6s ease;
        z-index: 2;
        // box-shadow: 0px 0px 5px rgba($green-shade-colour, 0.5);
    }

    .prev {
        left: 0;
        border-radius: 0 10px 10px 0;
    }

    .next {
        right: 0;
        border-radius: 10px 0 0 10px;
    }

    .prev:hover,
    .next:hover {
        background: rgba($pink-new-colour, 0.8);
        // box-shadow: 0px 0px 30px 1px rgba($green-shade-colour, 0.5);
        transform: scale(1.1);
    }

    .close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        width: 1.5rem;
        height: 1.5rem;
        background: transparent;
        z-index: 2;
        transition: 0.3s ease-out;

        svg {
            color: white;
        }

        &:hover {
            cursor: pointer;
            transform: scale(1.1);
        }
    }

    .pagination {
        position: absolute;
        bottom: 5px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        display: flex;
        flex-direction: row;
        align-items: baseline;
        gap: 5px;
        // background: grey;
        padding: 8px;
        border-radius: 5px;

        .dot {
            cursor: pointer;
            height: 10px;
            width: 10px;
            margin: 0 2px;
            background: #bbb;
            border-radius: 50%;
            display: inline-block;
            transition: background-color 0.6s ease;

            &.active {
                background: $pink-new-colour;

                height: 13px;
                width: 13px;
            }
        }
    }


    @media only screen and (max-width: 768px) {
        img {
            object-fit: contain;
        }
    }

    @media only screen and (max-width: 576px) {}
}