@import '../../../res/colours.scss';

.arrow_motion_button {
    transition: 0.5s;
    position: relative;


    &:after {
        content: '\276F';
        position: absolute;
        opacity: 0;
        top: 9px;
        right: -20px;
        transition: 0.5s;
        transform: scale(1.2);
    }

    &:hover:enabled {
        padding-right: 30px;
        padding-left: 12px;
        color: white;
    }

    &:hover:enabled:after {
        opacity: 1;
        right: 10px;
    }



    /* ===========================
   ====== Media Query's ====== 
   =========================== */
    @media only screen and (max-width: 576px) {
        &:after {
            top: 9px;
            transform: scale(1);
        }

        &:hover {
            // padding-right: 20px;
            // padding-left: 7px;
            padding-right: 25px;
            padding-left: 17px;
        }

        &:hover:after {
            right: 10px;
        }
    }
}