@import '../../res/colours.scss';
@import '../../constants/constants.scss';

.testimonials__section {
    background: $pink-new-colour;

    h1, h5 {
        color: #000;
    }

    .list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        // overflow-y: hidden;
        padding-bottom: 10px;
    }
}

@media only screen and (max-width: 992px) {}

@media only screen and (max-width: 768px) {}

@media only screen and (max-width: 576px) {
    
}