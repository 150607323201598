@import '../../../res/anim.scss';
@import '../../../res/colours.scss';
@import '../../../constants/constants.scss';

// https://uiverse.io/Shoh2008/little-termite-6
.testimonial-card {
    margin: 20px;
    padding: 20px;
    width: 400px;
    min-height: 200px;
    display: grid;
    grid-template-rows: 20px 30px .1fr;
    border-radius: 10px;
    // box-shadow: 10px 11px 15px rgba(0, 0, 0, 0.5);
    // box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2);
    // background: radial-gradient($pink-new-colour, #e5a3e3);
    background: white;
    transition: 0.5s;

    &:hover {
        // box-shadow: 10px 11px 15px rgba(0, 0, 0, 0.7);
        transform: scale(1.01);
    }
}

.card__link,
.card__exit,
.card__icon {
    position: relative;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.9);
}

.card__link::after {
    position: absolute;
    top: 25px;
    left: 0;
    content: "";
    width: 0%;
    height: 3px;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s;
}

.card__link:hover::after {
    width: 100%;
}

.title {
    color: black;
    font-size: 22px;
}

.description {
    color: black;
}

.card__exit {
    grid-row: 1/2;
    justify-self: end;
}

.card__icon {
    grid-row: 2/3;
    font-size: 30px;
    margin: 0 auto;

    svg {
        height: 1.2rem;
        color: $pink-dark-colour; //$gold-colour;
        // color: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
        //         radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
    }
}

.card__apply {
    grid-row: 4/5;
    align-self: center;
}

// @media (max-width: 1600px) {
//     .cards {
//         justify-content: center;
//     }
// }











/*.testimonial-card {
    @media screen and (max-width: 900px) {
        margin: 0rem;
    }
    width: 18rem;
    height: 21rem;
    margin: 1.1rem;
    border-radius: 10px;
    // perspective: 1000px;
    // background: white;


    &--wrapper {
        position: relative;
        // padding: 1.5rem 2rem;
        width: 100%;
        height: 100%;

        transform-origin: center;
        transition: transform 0.6s;
        transform-style: preserve-3d;


        .content {
            position: absolute;
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-radius: 10px;
            box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
            background: white;

            text-align: center;
            word-wrap: normal;
            white-space: normal;

            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;

            padding: 1.5rem 1.8rem;



            .test-star {
                height: 1.2rem;
                margin: 1.5rem;

                svg {
                    color: $gold-colour;
                    // color: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
                    //         radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);;
                }
            }

            .test-quote-icon {
                position: absolute;
                top: 0;
                left: 5;
                width: 1.5rem;
                height: 1.5rem;
                display: grid;
                place-items: center;
                border-radius: 50%;
                transform: translateY(25%);

                svg {
                    color: $gold-colour;
                }
            }

            .test-title {
                margin: 1rem 0;

                h1 {
                    font-size: 1.2rem;
                    text-transform: uppercase;
                    letter-spacing: 1.5px;
                    // text-shadow: rgba($pink-new-colour, 1);
                }
            }

            .test-description {
                overflow-x: scroll;

                // height: fit-content;
                // margin: 0 1rem;
                // font-family: cursive;

            }

            .test-footer {
                flex: 1;
                display: flex;
                position: absolute;
                bottom: 5px;
                left: 0;
                right: 0;
                justify-content: flex-end;
                align-items: center;
                margin: 0 2rem;
                padding: 0.5rem;
                border-top: 2px solid rgba($gold-colour, 0.5);

                span, h1, label {
                    font-family: cursive;
                }
            }
        }
    }
}*/