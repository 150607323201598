@keyframes growProgressBar {
    0%,
    33% {
        --pgPercentage: 0;
    }

    100% {
        --pgPercentage: var(--value);
    }
}

@property --pgPercentage {
    syntax: '<number>';
    inherits: false;
    initial-value: 0;
}

.circular-progress-bar {
    --size: 9rem;
    --bgColour: #44484b;
    --pgPercentage: var(--value);


    display: grid;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    border: none;
    place-items: center;
    background:
        radial-gradient(closest-side, var(--bgColour) 85%, transparent 0 99.9%, var(--bgColour) 0),
        conic-gradient(var(--barColour) calc(var(--pgPercentage) * 10%), white 0);
    // font-family: Helvetica, Arial, sans-serif;
    // font-size: calc(var(--size) / 5);
    // color: var(--barColour);
    animation: growProgressBar 3s 1 forwards;
    overflow: hidden;


    &::before {
        counter-reset: percentage var(--value);
    }
}


@media only screen and (max-width: 992px) {}

@media only screen and (max-width: 768px) {}

@media only screen and (max-width: 576px) {}