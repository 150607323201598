.frame-container {
    position: relative;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    // height: 0;
    width: 100%;
    height: 200px;
    overflow: hidden;
    // padding-bottom: 30.25%;
    background: white;


    iframe {
        // position: absolute;
        // left: 0;
        // top: 0;
        width: 100%;
        height: 100%;
    }
}