@import '../../../res/colours.scss';

.radio-group-options {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    border-radius: 0.5rem;
    background-color: #EEE;
    box-sizing: border-box;
    box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.06);
    padding: 0.25rem;
    width: 300px;
    font-size: 14px;
    

    .radio {
        flex: 1 1 auto;
        text-align: center;

        input {
            display: none;

            &:checked+.name {
                background-color: $pink-new-colour;
                font-weight: 600;
            }
        }

        .name {
            display: flex;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            border-radius: 0.5rem;
            border: none;
            padding: .5rem 0;
            color: rgba(51, 65, 85, 1);
            transition: all .15s ease-in-out;
        }
    }




    /* ===========================
   ====== Media Query's ====== 
   =========================== */
   @media only screen and (max-width: 992px) {}

   @media only screen and (max-width: 768px) {
        width: 200px;
        padding: 0.15rem;
        font-size: 12px;
   }

   @media only screen and (max-width: 576px) {
       font-size: 0.7rem;
       // padding: 4px 10px;
   }
}